document.addEventListener("turbolinks:load", function () {
  $image_crop = $('#upload-image').croppie({
    enableExif: true,
    viewport: {
      width: 200,
      height: 200,
      type: 'square'
    },
    boundary: {
      width: 300,
      height: 300
    }
  });

  $('#images').on('change', function () {
    var reader = new FileReader();
    reader.onload = function (e) {
      $image_crop.croppie('bind', {
        url: e.target.result
      }).then(function () {
        console.log('jQuery bind complete');
      });
    }
    reader.readAsDataURL(this.files[0]);
  });


  $('.crop-image').on('click', function (ev) {
    $image_crop.croppie('result', {
      type: 'canvas',
      size: 'original',
    }).then(function (response) {
      html = '<img src="' + response + '" >';
      $("#crop-image-preview").html(html);
      $("#crop-image-download").attr("href", response);
    });
  });

  $('#modal').on('shown.bs.modal', function () {
    $image_crop = $('#upload-image').croppie({
      enableExif: true,
      viewport: {
        width: 200,
        height: 150,
        type: 'square'
      },
      boundary: {
        width: 300,
        height: 300
      }
    });
  
    $('#images').on('change', function () {
      var reader = new FileReader();
      reader.onload = function (e) {
        $image_crop.croppie('bind', {
          url: e.target.result
        }).then(function () {
          console.log('jQuery bind complete');
        });
      }
      reader.readAsDataURL(this.files[0]);
    });
  
  
    $('.crop-image').on('click', function (ev) {
      $image_crop.croppie('result', {
        type: 'canvas',
        size: 'original',
      }).then(function (response) {
        html = '<img src="' + response + '" >';
        $("#crop-image-preview").html(html);
        $("#crop-image-download").attr("href", response);
      });
    });
  })
});
