document.addEventListener("turbolinks:load", function () {
  $(".alt-list-item-row").on('click', function () {
    var tr = $(this).closest("tr");
    
    if (tr.hasClass("selected")) {
      tr.removeClass("selected");
    } else {
      tr.addClass("selected");
    }
  }).on('click', 'div', function(e) {
    e.stopPropagation();
  });

  $(".calendar-service-items-edit-form").submit(function(e){
    e.preventDefault();
    var serviceIds = $(".alt-list-item-row .service-id-input").map(function (_, e) { return $(e).html() });
    var calendarIds = $(".alt-list-item-row .calendar-id-input").map(function (_, e) { return $(e).html() });
    var steps = $(".alt-list-item-row .step-input").map(function (_, e) { return $(e).val() });
    var spots = $(".alt-list-item-row .spot-input").map(function (_, e) { return $(e).val() });
    var maxSpotsPerUser = $(".alt-list-item-row .max-spots-per-user-input").map(function (_, e) { return $(e).val() });
    var prices = $(".alt-list-item-row .price-input").map(function (_, e) { return $(e).val() });
    var durations = $(".alt-list-item-row .duration-input").map(function (_, e) { return $(e).val() });
    var minimal_start_delay = $(".alt-list-item-row .minimal-start-delay-input").map(function (_, e) { return $(e).val() });
    var after_reservation_delay = $(".alt-list-item-row .after-reservation-delay-input").map(function (_, e) { return $(e).val() });
    var selected = $(".alt-list-item-row").map(function (_, e) { return $(e).hasClass("selected") });

    var url = $(".calendar-service-items-edit-form").attr("action")

    $.ajax({
      type: "POST",
      url: window.location.origin + url,
      data: { 
        serviceIds: serviceIds.toArray(),
        calendarIds: calendarIds.toArray(),
        steps: steps.toArray(),
        spots: spots.toArray(),
        prices: prices.toArray(),
        durations: durations.toArray(),
        max_spots_per_user: maxSpotsPerUser.toArray(),
        minimal_start_delay: minimal_start_delay.toArray(),
        after_reservation_delay: after_reservation_delay.toArray(),
        selected: selected.toArray(),
      }
    });
    
    return false;
  });    

  $(".price-rule-items-edit-form").submit(function(e){
    e.preventDefault();
    
    var calendarIds = $(".alt-list-item-row.selected .calendar-id-input").map(function (_, e) { return $(e).html() });
    var serviceIds = $(".alt-list-item-row.selected .service-id-input").map(function (_, e) { return $(e).html() });

    var url = $(".price-rule-items-edit-form").attr("action")

    $.ajax({
      type: "POST",
      url: window.location.origin + url,
      data: { 
        calendarIds: calendarIds.toArray(),
        serviceIds: serviceIds.toArray(),
      }
    });
    
    return false;
  });    

  $(".promo-create-form").submit(function(e){
    e.preventDefault();
    
    var url = $(".promo-create-form").attr("action")
    
    var serviceIds = $(".alt-list-item-row.selected .service-id-input")
      .map(function (_, e) { return $(e).html() })
      .toArray();
    var formData = $(this).serializeArray()
    formData.push({ name: 'serviceIds', value: serviceIds}),
    
    $.post(window.location.origin + url, $.param(formData));
    return false;
  });
});