document.addEventListener("turbolinks:load", function () {
  
  // Collection items 
  
  $(".collection-item-list" ).sortable();
  $(".collection-item-list" ).disableSelection();

  $(".add-collection-item-select").select2({
    width: "100%",
    theme: "bootstrap"
  });

  $(".add-collection-item-select-button").on("click", function() {
    var selectedItem = $(".add-collection-item-select").find(':selected');
    
    if (typeof selectedItem.val() == "undefined") {
      console.log("nothing selected")
      return
    }

    var newItem = $(".collection-item-template").find(".collection-item").clone(true);

    var id = selectedItem.val();
    var title = selectedItem.data("title");
    var thumbnail = selectedItem.data("thumbnail");
    var companyTitle = selectedItem.data("company-title")
    
    newItem.find(".collection-item-id").val(id);
    newItem.find(".collection-item-image").attr("src", decodeURIComponent(thumbnail));
    newItem.find(".collection-item-title").text(decodeURIComponent(title));

    if (typeof companyTitle != "undefined") {
      newItem.find(".collection-item-company").text(decodeURIComponent(companyTitle));
    }

    if ($(".collection-item-list").children().length > 0) {
      $(".collection-item-list").find(".collection-item").first().before(newItem)
    } else {
      $(".collection-item-list").append(newItem)
    }
    
    $(".add-collection-item-select").val('').trigger('change');    
  });
  
  $(".collection-item-remove").on("click", function() {
    $(this).closest(".collection-item").remove();
  })

  // Collection order items 

  $(".collection-order-item-list" ).sortable();
  $(".collection-order-item-list" ).disableSelection();

  $(".add-collection-order-item-select").select2({
    width: "100%",
    theme: "bootstrap"
  });

  $(".add-collection-order-item-select").submit(function(e) {
    e.preventDefault()
    true
  })

  $(".add-collection-order-item-select-button").on("click", function() {
    var selectedItem = $(".add-collection-order-item-select").find(':selected');
    
    if (typeof selectedItem.val() == "undefined") {
      console.log("nothing selected")
      return
    }

    var newItem = $(".collection-order-item-template").find(".collection-order-item").clone(true);
    newItem.find(".collection-order-item-id").val(selectedItem.val());
    newItem.find(".collection-order-item-title").text(selectedItem.text());

    if ($(".collection-order-item-list").children().length > 0) {
      $(".collection-order-item-list").find(".collection-order-item").first().before(newItem)
    } else {
      $(".collection-order-item-list").append(newItem)
    }
    
    $(".add-collection-order-item-select").val('').trigger('change');    
  });

  $(".collection-order-item-remove").on("click", function() {
    $(this).closest(".collection-order-item").remove();
  })
});