document.addEventListener("turbolinks:load", function () {

    $(".promo-list-header-checkbox").change(function() {
        var isChecked = this.checked
        var $table = $(this).parents("table")
        $table.find("tbody tr").each(function() {
            $(this).find(".promo-list-checkbox").prop( "checked", isChecked )
        })
    });

    $(".promo-list-form").submit(function(e){
        e.preventDefault();
        
        var ruleIds = $(".promo-list-item-row")
            .filter(function(_, item) { return $(item).find(".promo-list-checkbox:first").is(":checked")})
            .map(function(_, item) { return $(item).find(".promo-list-id:first").html() })
            .toArray()
        
        var url = $(".promo-list-form").attr("action")
        $.post(window.location.origin + url, {
            ruleIds: ruleIds,
        });
        return false;
      });

    $(".promo-code-list-header-checkbox").change(function() {
        var isChecked = this.checked
        var $table = $(this).parents("table")
        $table.find("tbody tr").each(function() {
            $(this).find(".promo-code-list-checkbox").prop( "checked", isChecked )
        })
    });

    $(".promo-code-list-form").submit(function(e){
        e.preventDefault();
        
        var codeIds = $(".promo-code-list-item-row")
            .filter(function(_, item) { return $(item).find(".promo-code-list-checkbox:first").is(":checked")})
            .map(function(_, item) { return $(item).find(".promo-code-list-id:first").html() })
            .toArray()
        
        var url = $(".promo-code-list-form").attr("action")
        $.post(window.location.origin + url, {
            codeIds: codeIds,
        });
        return false;
    });

    $("#promo-code-list-apply-filters").on('click', function (e) {
        e.preventDefault();

        var $filters = $(this).parents("#collapseFilters")
        var showDeactivated = $filters.find(".promo-code-filter-deactivated:first").is(":checked")
        var companyId = $filters.find(".promo-code-filter-company:first").val().trim()
        var userId = $filters.find(".promo-code-filter-user:first").val().trim()
    
        var data = {
            show_deactivated: showDeactivated,
            company_id: companyId,
            user_id: userId,
          };
        var url = e.target.baseURI.replace(/\?.*$/, "") + "?" + jQuery.param(data);
          
        console.log(url);
        location.href = url
        
        return false
    });
});