// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.

import('css/application')

require("@rails/ujs").start()
require("turbolinks").start()
require("select2")

require("popper.js")
require("bootstrap")
require("jquery")
require("jquery-ui")
require("croppie")
require("croppie/croppie.css")

require("packs/alt_list_item_selection")
require("packs/contacts")
require("packs/table_search")
require("packs/file_upload")
require("packs/collections")
require("packs/reservations")
require("packs/delete_user")
require("packs/image")
require("packs/promos_list")
require("packs/countries_list")
require("packs/cancellations_list")

window.jQuery = jQuery
window.$ = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)


// Prevent saving changes in any form on "enter" key press
$(document).on("keydown", ":input:not(textarea)", function(event) {
    if (event.key == "Enter") {
        event.preventDefault();
    }
});