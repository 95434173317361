document.addEventListener("turbolinks:load", function () {
  $(function () {
    $(".contact-button").on('click', function () {
      var ele = $('.contact-fields').last().clone(true);
      $('.contact-fields').last().after(ele);
    })
  });


  $(function () {
    $(".contact-remove-button").on('click', function () {
      if ($('.contact-fields').length > 1) {
        $(this).closest(".contact-fields").remove();
      }
    })
  });
});