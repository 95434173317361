document.addEventListener("turbolinks:load", function () {
  $(".browse").on("click", function () {
    var file = $(this).parents().find(".image-file");
    file.trigger("click");
  });
  $(".image-file").change(function (e) {
    var fileName = e.target.files[0].name;
    $(".image-file-text").val(fileName);

    var reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById("preview").src = e.target.result;
    };

    reader.readAsDataURL(this.files[0]);
  });
});
