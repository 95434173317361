document.addEventListener("turbolinks:load", function () {
  $(".user-delete-form").submit(function(e){

    var phone = $(".initial-phone-field").text();
    var inputPhone = $(".confirm-phone-input").val();


    if (phone != inputPhone) {
      e.preventDefault();
      alert("Incorrect phone number")
      return false
    };
  });    
});