document.addEventListener("turbolinks:load", function () {

    $(".cancellations-list-header-checkbox").change(function() {
        var isChecked = this.checked
        var $table = $(this).parents("table")
        $table.find("tbody tr").each(function() {
            $(this).find(".cancellations-list-checkbox").prop( "checked", isChecked )
        })
    });

    $(".cancellations-list-form").submit(function(e){
        e.preventDefault();
        
        var ruleIds = $(".cancellations-list-item-row")
            .filter(function(_, item) { return $(item).find(".cancellations-list-checkbox:first").is(":checked")})
            .map(function(_, item) { return $(item).find(".cancellations-list-id:first").html() })
            .toArray()
        
        var url = $(".cancellations-list-form").attr("action")
        $.post(window.location.origin + url, {
            ruleIds: ruleIds,
        });
        return false;
      });

    $(".cancellations-list-header-checkbox").change(function() {
        var isChecked = this.checked
        var $table = $(this).parents("table")
        $table.find("tbody tr").each(function() {
            $(this).find(".cancellations-list-checkbox").prop( "checked", isChecked )
        })
    });

    $(".cancellations-list-form").submit(function(e){
        e.preventDefault();
        
        var cancellationIds = $(".cancellations-list-item-row")
            .filter(function(_, item) { return $(item).find(".cancellations-list-checkbox:first").is(":checked")})
            .map(function(_, item) { return $(item).find(".cancellations-list-id:first").html() })
            .toArray()

        var url = $(".cancellations-list-form").attr("action")
        $.post(window.location.origin + url, {
            cancellationIds: cancellationIds,
        });
        return false;
    });
});