document.addEventListener("turbolinks:load", function () {

    $(".countries-list-form").submit(function(e){
        e.preventDefault();
        
        var countryCodes = $(".countries-list-item-row")
            .filter(function(_, item) { return $(item).find(".countries-list-checkbox:first").is(":checked")})
            .map(function(_, item) { return $(item).find(".countries-list-id:first").html() })
            .toArray()
        
        var url = $(".countries-list-form").attr("action")
        $.post(window.location.origin + url, {
            countryCodes: countryCodes,
        });
        return false;
      });

});